const ServiceData = {
    services: [
        {
          id:"ss1",
          icon:"fa fa-file-invoice",
          title: "Design & Vreatives",
          text: "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
          iconBackground:"#0041ff"
        },
        {
          id:"ss2",
          icon:"fa fa-bell",
          title: "Chandrasekaran's approach for thyromegaly",
          text:
            "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#ff1e38"
        },
        {
          id:"ss3",
          icon:"fa fa-bell",
          title: "Chandrasekaran's approach for thyromegaly",
          text:
            "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#fcdc00"
        },
        {
          id:"ss4",
          icon:"fa fa-headphones",
          title: "Customer Support",
          text:
            "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#ff5200"
        },
        {
          id:"ss5",
          icon:"fa fa-envelope",
          title: "Email Marketing",
          text:"There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#1de2cf"
        },
        {
          id:"ss6",
          icon:"fa fa-layer-group",
          title: "Digital Agency",
          text:
            "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#6001d3"
        }
    ]
}
  export default ServiceData;